.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 5px 10px;
  text-align: center;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  &:hover, &:focus, &:active {
    outline: none;
  }
}
.btn-outline {
  background: #ffffff;
  border: 1px solid #3B7AC5;
  font-weight: 500;
  color: #3B7AC5;
}

/*COLORS*/
.btn_primary {
  background: linear-gradient(90deg, #FC7884 0%, rgba(255, 255, 255, 0) 100%), #FF606E;
  box-shadow: 0 1px 15px rgba(255, 96, 110, 0.35);
  &:hover, &:focus, &:active, &[disabled] {
    box-shadow: none;
    background: rgba(255, 96, 110, .6);
  }
}
.btn_secondary {
  background: linear-gradient(90deg, #7FC9FE 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #70B1FF, #70B1FF), rgba(59, 122, 197, 0.2);
  box-shadow: 0 1px 20px rgba(112, 177, 255, 0.35);
  &:hover, &:focus, &:active {
    box-shadow: none;
    background: rgba(112, 177, 255, .6);
  }
}

/*SIZES*/
.btn_sm {
  font-size: 14px;
  line-height: 17px;
}
.btn_xl {
  font-size: 18px;
  line-height: 22px;
}
.btn_block {
  width: 100%;
  position: relative;
}

.buttons-list {
  display: flex;
  margin: -20px -5px 0;
  padding: 20px 0;
  min-height: 80px;

  .btns-wrapper {
    flex: 1 1 1px;
    min-width: 0;
    display: inline-block;
    position: relative;
    padding: 0 5px;
    opacity: .6;
    &.is-selected {
      opacity: 1;

      .btn_primary {
        background: linear-gradient(90deg, #FC7884 0%, rgba(255, 255, 255, 0) 100%), #FF606E !important;
        box-shadow: 0 1px 15px rgba(255, 96, 110, 0.35);
      }

      .btn_secondary {
        background: linear-gradient(90deg, #7FC9FE 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #70B1FF, #70B1FF), rgba(59, 122, 197, 0.2) !important;
        box-shadow: 0 1px 20px rgba(112, 177, 255, 0.35);
      }
    }
  }

  .btn-close {
    position: absolute;
    top: -10px;
    right: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #444;
    border: 0;
    line-height: 25px;
    color: #fff;
    outline: none;
  }
}

.desktop-site-button  {
  margin-right: 5px;
  margin: 0 auto;
  width: 300px;
  .btn {
    background: #ec606dcf;
  }

  a {
    text-decoration: none;
  }
}