button {
  &.in-processing {
    position: relative;

    .spinner {
      visibility: visible;
    }
  }

  .spinner {
    position: absolute;
    right: 6px;
    width: 30px;
    height: 30px;
    padding: 0;
    visibility: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
