
.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  @media (orientation: landscape) {
    margin-bottom: 25px;
  }
  &_m0 {
    margin-bottom: 0;
  }
  .form-message {
    margin-bottom: -38px;
  }
  .form-message_top {
    margin-bottom: 0;
    margin-top: -38px;
  }

  &.invalid {
    label {
      color: #FF606E;
    }
    .form-text {
      outline: none;
      box-shadow: 0 0 3px 1px #FF606E;
    }
  }
}
.form-message {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: right;
  font-size: 12px;
  line-height: 18px;
  &_error {
    color: #FF606E;
  }
  &_success {
    color: #73E960;
  }
}

.form-text {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #ffffff;
  font: 300 16px/20px "Montserrat", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #555555;
  -webkit-appearance: none;

  &:hover, &:focus, &:active {
    outline: none;
    box-shadow: 0 0 3px 1px #70B1FF;
  }

  &[readonly] {
    &:hover, &:focus, &:active {
      box-shadow: none;
    }
  }

  &[type=number],
  input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & + .float-label {
    font: 300 16px/20px "Montserrat", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    color: #555555;
    -webkit-appearance: none;
  }

  &.has-input {
    text-align: left;
    display: flex;
    align-items: flex-start;

    label {
      display: flex;
      flex: 1 1 auto;
      color: #555555;
      white-space: nowrap;
    }

    .input-text {
      display: flex;
      flex: 1 1 auto;

      input {
        display: block;
        width: 100%;
        border: none;
        margin: 0;
        padding: 0 0 0 5px;
        background-color: transparent;
        outline: none;
        font: 300 16px/20px "Montserrat", "Roboto", "Oxygen",
          "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;
        color: #555555;
        -webkit-appearance: none;
        text-align: right;
      }
    }
  }
}
.form-text::-webkit-input-placeholder {color: #555555;}
.form-text::-moz-placeholder {color: #555555;}
.form-text:-ms-input-placeholder {color: #555555;}
.form-text:-moz-placeholder {color: #555555;}

.form-text:-webkit-autofill,
.form-text:-webkit-autofill:hover,
.form-text:-webkit-autofill:focus,
.form-text:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #555555;
}

.form-tex_sm {
  height: 30px;
  padding: 5px 15px;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0,0,0,.9);

  & + .float-label {
    font-size: 13px;
    line-height: 16px;
    color: rgba(0,0,0,.9);
  }
}

.form-tex_ssm {
  height: 30px;
  padding: 5px 15px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0,0,0,.9);

  & + .float-label {
    font-size: 16px;
    line-height: 17px;
    color: rgba(0,0,0,.9);
  }

  @media screen and (max-width: 350px) {
    padding: 5px 10px;
    font-size: 14px;

    & + .float-label {
      font-size: 14px;
    }
  }
}

.new-pick-text {
  margin-right: -10px;
  width: auto;
}

.form-text_sm::-webkit-input-placeholder {color: #ABAFB7;}
.form-text_sm::-moz-placeholder {color: #ABAFB7;}
.form-text_sm:-ms-input-placeholder {color: #ABAFB7;}
.form-text_sm:-moz-placeholder {color: #ABAFB7;}

.form-text_sm:-webkit-autofill,
.form-text_sm:-webkit-autofill:hover,
.form-text_sm:-webkit-autofill:focus,
.form-text_sm:-webkit-autofill:active {
  -webkit-text-fill-color: #ABAFB7;
}

/*CUSTOM CHECKBOX*/
.checkboxes-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .form-checkbox {
    margin-right: 8px;
    white-space: nowrap;
  }

  .checkbox-label-text + .checkbox-label-outer,
  .checkbox-label-outer + .checkbox-label-text {
    margin-left: 5px;
  }
}
.form-checkbox {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.checkbox-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkbox-label-outer {
  display: flex;
  justify-content: flex-start;
  flex: 0 0 40px;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background: #DDE0E6;
  border: 2px solid #DDE0E6;
  cursor: pointer;
}
.checkbox-label-inner {
  display: block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
}
.form-checkbox .checkbox-label .checkbox-input:checked + .checkbox-label-outer,
.form-checkbox .checkbox-input:checked + .checkbox-label .checkbox-label-outer {
  justify-content: flex-end;
  background: #73E960;
  border-color: #73E960;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  img {
    width: 15px;
    height: 14px;
  }
}

.info-container:hover::before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 5px;
  background-color: rgba(0, 0, 0, .6);
  width: 300px;
  border-radius: 7px;
  padding: 6px;
  color: white;
  font-size: 14px;
  white-space: pre-wrap;
}

.setting-isUrgent {
  .info-container:hover::before {
    content: "Check this box if you expect the market to drop soon due to any reason.";
  }
}

@media (max-width: 576px) {
  .setting-highValue {
    .info-container::before {
      left: -68px;
    }
  }
}

@media (min-width: 415px) and (max-width: 576px) {
  .setting-teamNews {
    .info-container::before {
      left: -117px;
    }
  }
}

.setting-highValue {
  .info-container:hover::before {
    content: "When you believe this bet is a very high value bet, much larger than usual situations/bets.";
  }
}

.setting-teamNews {
  .info-container:hover::before {
    content: "When you believe the news you've received about the game is new/recent and the market will drop soon due to this information.";
  }
}


/*CUSTOM SELECT*/
.select {
  width: 100%;
  .select__control  {
    background-color: #ffffff;
    border-color: #DDE0E6;
    border-radius: 100px;
    cursor: pointer;
    flex-wrap: wrap;
    min-height: inherit;
    height: 30px;
  }
  .select__value-container {
    padding: 2px 0 2px 20px;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
  .select__indicator-separator {
    display: none;
  }
  .select__dropdown-indicator {
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 100%;
    padding: 0;
    svg {
      display: none;
    }
    &:after {
      display: inline-block;
      content: url("../images/icons/arrow-down.svg");
    }
  }
  .select__menu {
    z-index: 100;
  }
}

/*SEARCH FORM*/
.search-form {
  .form-text {
    padding-right: 80px;
  }
  .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;

    &.in-processing > img {
      visibility: hidden;
    }
  }

  .search-list {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
    max-height: calc(100vh - 192px);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px 20px;
    z-index: 100;
    border-radius: 15px;
    box-shadow: 0 1px 10px rgba(128,128, 128, 0.5);
  }

  .search-list-part li {
    padding: 5px 0;
  }

  .search-list-part + .search-list-part {
    margin-top: 10px;
  }

  .is-live-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff606e;
    margin-right: 5px;
    margin-bottom: 1px;
  }
}

.new-pick-title {
  background: white;
  border: 1px solid #DDE0E6;
  border-radius: 16px;
  padding: 5px 10px;
  line-height: 1.5;
}
