.notification {
  position: relative;
  margin: 0;
  background: #fff;
  color: black;

  display: flex;
}

.icon {
  width: 40px;
  padding: 10px 0 10px 10px;

  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}

.content {
  flex: 1 1 auto;
  padding: 10px;
  font-size: 12px;

  h2 {
    font-size: 15px;
    margin: 0 0 5px;
  }
  p {
    margin: 0;
  }
}