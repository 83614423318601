@import "reset";

@import "buttons";
@import "forms";
@import "spinner";
@import "modal";

.text-right {
  text-align: right;
}

.text-ellipsis {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.for-img {
  text-align: center;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
}

.cover-img {
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
}

body, button, input {
  font-family: "Montserrat", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  display: flex;
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  display: flex;
  background: #F6F6F6;
  overflow: hidden;
}
.app {
  width: 100%;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nowrap {
  white-space: nowrap;
}
