.ReactModal__Content {
  .btn-modal-close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #444;
    border: 0;
    line-height: 25px;
    color: #fff;
    outline: none;
  }
}

.modal {
  .icon-holder {
    text-align: center;
    margin: 0 0 10px;
  }
}

.auth-modal {
  text-align: center;
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.forgot-password-modal {
  .link-box {
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0;

    a {
      color: #f52539;
    }
  }
}

.bets-limit-modal {
  .modal-action {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 10px 0 0;
    a {
      display: inline-block;
      margin: 10px 0;
    }
    .btn {
      margin: 10px 0;
      background-color: rgb(221,221,221);
    }
  }
}

.rejected-bet-modal {
  .rejected-bet-modal-title {
    text-align: center;
    font-weight: bold;;
  }

  .rejected-bet-modal-text {
    text-align: center;
  }
}

.bet-limit-modal {
  .bet-limit-modal-title {
    font-weight: bold;;
  }
}

.bet-settings-modal {
  .bet-settings-title {
    text-align: center;
  }

  .bet-settings-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 17px auto;
    .checkbox-bet-settings {
      margin-right: 10px;
    }

    .checkbox-label-text {
      padding-right: 5px;
    }
  }

  .bet-settings-notes-container {
    display: flex;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
    .form-text {
      border: 1px solid rgba(0,0,0,0.15);
      width: 50%;
      margin-left: 10px;
    }
  }

  .action-container {
    width: 50%;
    margin: 0 auto;
  }
}