.auth-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;

  @media (orientation: landscape) {
    min-height: 332px;
    padding: 0 10px;
  }
}
.auth-inner {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.auth-logo {
  margin: auto;
}
.auth-link {
  display: inline-block;
  margin: 15px 0;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-decoration: none;
  &:hover, &:focus, &:active {
    outline: none;
    text-decoration: underline;
  }
}
.auth-additional-actions {
  display: flex;
  justify-content: space-between;
}
