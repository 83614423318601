.wait-modal-wrapper {
  .modal-title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #366fb1;
  }
  
  .modal-text {
    font-size: 14px;
    line-height: 1.43;
    color: #38415f;
    display: block;
    text-align: center;
  }

  .pending-modal {
    text-align: center;
    margin-bottom: 10px;
  }
}