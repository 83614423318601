.refresh-modal {
  .btn {
    max-width: 20%;
    min-width: 250px;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  
  .title-wrapper {
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;
  }
}