.spinner {
  text-align: center;
  padding-top: 10px;

  img {
    vertical-align: top;
    width: 1.8125em;
    height: 1.8125em;
  }

  &.center-page {
    position: absolute;
    padding-top: 0;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}