.no-connection {
  position: fixed;
  top: 20px;
  left: 50%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 15px;
  z-index: 10000;
  padding: 20px;
  border-radius: 20px;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
}